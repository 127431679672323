<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              :alt="appName"
              max-height="30px"
              contain
              class="me-3 img-fluid"
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="mb-2">
            Please sign-in to your account
          </p>
        </v-card-text>

        <v-card-text>
          <v-alert
            v-if="loginError"
            dense
            text
            dismissible
            color="error"
            class="py-2"
          >
            {{ loginError }}
          </v-alert>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="username"
              outlined
              label="Username"
              placeholder=""
              :error-messages="errorMessages.username"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder=""
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="errorMessages.password"
              :rules="[validators.required]"
              hide-details="auto"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-divider class="my-6"></v-divider>
            <!--
            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

              <router-link
                :to="{name:'auth-forgot-password-v1'}"
                class="mt-1"
              >
                Forgot Password?
              </router-link>
            </div>
            -->

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
              :loading="isLoggingIn"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <v-img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    ></v-img>

    <!--
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
    -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import axios from '@axios'

import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const isLoggingIn = ref(false)
    const isPasswordVisible = ref(false)
    const username = ref(null)
    const password = ref()
    const errorMessages = ref([])
    const loginError = ref()

    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      isLoggingIn.value = true
      loginError.value = null

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      axios
        .post('/user/login', {
          username: username.value,
          password: password.value,
        })
        // eslint-disable-next-line consistent-return
        .then(response => {
          const {
            code,
            message,
            token,
            role,
          } = response.data
          if (code !== '0') {
            loginError.value = message

            return Promise.reject(message)
          }

          localStorage.setItem('accessToken', token)

          // Todo: get user info from API?
          localStorage.setItem('userData', JSON.stringify({
            role,
            username: username.value,
          }))

          return response
        })
        .then(response => {
          // Todo: get user info from API?
          const {
            role,
          } = response.data

          // Mockup permission
          let user = {}
          if (role === 'admin') {
            user = { ability: [{ action: 'manage', subject: 'all' }] }
          } else {
            user = {
              ability: [
                { action: 'read', subject: 'Public' },
                { action: 'read', subject: 'User' },
              ],
            }
          }

          const { ability: userAbility } = user
          vm.$ability.update(userAbility)

          // Set user's ability in localStorage for Access Control
          localStorage.setItem('userAbility', JSON.stringify(userAbility))

          // On success redirect to home
          router.push('/')

          // Todo: get user info from API?
          // axios.get('/user/me').then(response => {
          //   const { user } = response.data
          //   const { ability: userAbility } = user
          //
          //   // Set user ability
          //   // ? https://casl.js.org/v5/en/guide/intro#update-rules
          //   vm.$ability.update(userAbility)
          //
          //   // Set user's ability in localStorage for Access Control
          //   localStorage.setItem('userAbility', JSON.stringify(userAbility))
          //
          //   // We will store `userAbility` in localStorage separate from userData
          //   // Hence, we are just removing it from user object
          //   delete user.ability
          //
          //   // Set user's data in localStorage for UI/Other purpose
          //   localStorage.setItem('userData', JSON.stringify(user))
          //
          //   // On success redirect to home
          //   router.push('/')
          // })
        })
        .catch(() => {
          console.error('Oops, Unable to login!')
        })
        .finally(() => { isLoggingIn.value = false })
    }

    return {
      handleFormSubmit,

      isLoggingIn,
      isPasswordVisible,
      username,
      password,
      errorMessages,
      loginError,
      validators: {
        required,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      loginForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
